<template>
  <TheLayout :wizardSteps="wizardSteps" :activeComponent="activeComponent">
    <SelectPaymentMethod
      v-if="activeComponent === 'SelectPaymentMethod'"
      v-model:paymentMethod="paymentMethodValue"
      v-model:paymentFrequencyDetails="paymentFrequencyDetailsValue"
      v-model:activeComponent="activeComponent"
      :user="user"
      :theSteps="theSteps"
    />
    <DebitOrderDetails
      v-if="activeComponent === 'DebitOrderPaymentDetails'"
      v-model:bankAccountDetails="bankAccountDetailsValue"
      v-model:paymentFrequencyDetails="paymentFrequencyDetailsValue"
    />
    <AnonymousDetails
      v-if="activeComponent === 'AnonymousDonorDetails'"
      v-model:anonymous="anonymousValue"
    />
    <UserDetails
      v-if="activeComponent === 'UserDonorDetails'"
      v-model:donatingAs="donatingAsValue"
      :sadaqahUserDonatingType="sadaqahUserDonatingType"
    />
    <ReviewDonation
      v-if="activeComponent === 'ReviewDonation'"
      v-model:reference="referenceValue"
      v-model:acceptTerms="acceptTerms"
      v-model:activeComponent="activeComponent"
      :sadaqahUserDonatingType="sadaqahUserDonatingType"
      :donatingAs="donatingAs"
      :user="user"
      :anonymous="anonymousValue"
      :paymentMethod="paymentMethodValue"
      :paymentFrequencyDetails="paymentFrequencyDetailsValue"
      :bankAccountDetails="bankAccountDetailsValue"
      :totalDailySadaqah="totalDailySadaqah"
    />

    <Footer>
      <SelectPaymentMethodFooter
        v-if="activeComponent === 'SelectPaymentMethod'"
        v-model:activeComponent="activeComponent"
        :paymentMethod="paymentMethodValue"
        :theSteps="theSteps"
        @close="$emit('closeWizard')"
      />
      <DebitOrderDetailsFooter
        v-if="activeComponent === 'DebitOrderPaymentDetails'"
        v-model:activeComponent="activeComponent"
        :theSteps="theSteps"
        :bankAccountDetails="bankAccountDetailsValue"
        :paymentFrequencyDetails="paymentFrequencyDetailsValue"
        @close="$emit('closeWizard')"
      />
      <AnonymousDetailsFooter
        v-if="activeComponent === 'AnonymousDonorDetails'"
        v-model:activeComponent="activeComponent"
        :theSteps="theSteps"
        :anonymousValue="anonymousValue"
        @close="$emit('closeWizard')"
      />
      <UserDetailsFooter
        v-if="activeComponent === 'UserDonorDetails'"
        v-model:activeComponent="activeComponent"
        :theSteps="theSteps"
        @close="$emit('closeWizard')"
      />
      <ReviewDonationFooter
        v-if="activeComponent === 'ReviewDonation'"
        v-model:activeComponent="activeComponent"
        :theSteps="theSteps"
        :acceptTerms="acceptTerms"
        @close="$emit('closeWizard')"
        @save="save"
        :isLoading="isLoading"
      />
    </Footer>
  </TheLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    TheLayout: defineAsyncComponent(() => import('./display/TheLayout.vue')),
    SelectPaymentMethod: defineAsyncComponent(() => import('./steps/selectPaymentMethod/SelectPaymentMethod.vue')),
    SelectPaymentMethodFooter: defineAsyncComponent(() => import('./steps/selectPaymentMethod/footer/SelectPaymentMethodFooter.vue')),
    DebitOrderDetails: defineAsyncComponent(() => import('./steps/debitOrderDetails/DebitOrderDetails.vue')),
    DebitOrderDetailsFooter: defineAsyncComponent(() => import('./steps/debitOrderDetails/footer/DebitOrderDetailsFooter.vue')),
    AnonymousDetails: defineAsyncComponent(() => import('./steps/anonymousDetails/AnonymousDetails.vue')),
    AnonymousDetailsFooter: defineAsyncComponent(() => import('./steps/anonymousDetails/footer/AnonymousDetailsFooter.vue')),
    UserDetails: defineAsyncComponent(() => import('./steps/userDetails/UserDetails.vue')),
    UserDetailsFooter: defineAsyncComponent(() => import('./steps/userDetails/footer/UserDetailsFooter.vue')),
    ReviewDonation: defineAsyncComponent(() => import('./steps/reviewDonation/ReviewDonation.vue')),
    ReviewDonationFooter: defineAsyncComponent(() => import('./steps/reviewDonation/footer/ReviewDonationFooter.vue')),
    Footer: defineAsyncComponent(() => import('./display/Footer.vue'))
  },
  name: 'Daily Sadaqah Wizard',
  props: ['paymentMethod', 'bankAccountDetails', 'paymentFrequencyDetails', 'reference', 'anonymous', 'donatingAs', 'sadaqahUserDonatingType', 'isLoading', 'totalDailySadaqah'],
  emits: ['update:paymentMethod', 'update:bankAccountDetails', 'update:paymentFrequencyDetails', 'update:reference', 'update:anonymous', 'update:donatingAs', 'closeWizard', 'save'],
  data () {
    return {
      activeComponent: 'SelectPaymentMethod',
      acceptTerms: false
    }
  },
  computed: {
    ...mapGetters([
      'isApp', 'isMobile', 'user'
    ]),
    wizardSteps () {
      return [
        {
          stepNumber: 1,
          title: 'Payment<br/>Details',
          checkActiveString: 'payment'
        },
        {
          stepNumber: 2,
          title: 'Donor<br/>Details',
          checkActiveString: 'donor'
        },
        {
          stepNumber: 3,
          title: 'Complete<br/>Setup',
          checkActiveString: 'review'
        }
      ]
    },
    theSteps () {
      let steps = ['SelectPaymentMethod']
      if (this.paymentMethodValue === 'DEBIT_ORDER') {
        if (this.user) {
          steps = ['SelectPaymentMethod', 'DebitOrderPaymentDetails', 'UserDonorDetails', 'ReviewDonation']
        } else {
          steps = ['SelectPaymentMethod', 'DebitOrderPaymentDetails', 'AnonymousDonorDetails', 'ReviewDonation']
        }
      } else {
        steps = ['SelectPaymentMethod', 'UserDonorDetails', 'ReviewDonation']
      }
      return steps
    },
    paymentMethodValue: {
      get () {
        return this.paymentMethod
      },
      set (val) {
        this.$emit('update:paymentMethod', val)
      }
    },
    bankAccountDetailsValue: {
      get () {
        return this.bankAccountDetails
      },
      set (val) {
        this.$emit('update:bankAccountDetails', val)
      }
    },
    paymentFrequencyDetailsValue: {
      get () {
        return this.paymentFrequencyDetails
      },
      set (val) {
        this.$emit('update:paymentFrequencyDetails', val)
      }
    },
    referenceValue: {
      get () {
        return this.reference
      },
      set (val) {
        this.$emit('update:reference', val)
      }
    },
    anonymousValue: {
      get () {
        return this.anonymous
      },
      set (val) {
        this.$emit('update:anonymous', val)
      }
    },
    donatingAsValue: {
      get () {
        return this.donatingAs
      },
      set (val) {
        this.$emit('update:donatingAs', val)
      }
    },
    organisationIDValue: {
      get () {
        return this.organisationID
      },
      set (val) {
        this.$emit('update:organisationID', val)
      }
    }
  },
  methods: {
    save () {
      this.$emit('save')
    }
  }
}

</script>
